import axiosBase from './AxiosBase';

export const ListHelpCategory = async (data) => {
    return await axiosBase.post('/Help/ListCategory', data);
}
export const ListHelpContent = async (data) => {
    return await axiosBase.post('/Help/ListContent', data);
}
export const ListCategoryContent = async (data) => {
    return await axiosBase.post('/Help/ListCategoryContent/9', data);
}