import { Container, Nav, Navbar, Row, Col } from 'react-bootstrap';
import { Outlet, Link } from "react-router-dom";

const Layout = () => {
    return (
        <>
            <Container className='mb-5'>
                <Outlet />
                <Row className='justify-content-md-center mt-5'>
                    <Col md="auto" className="text-center">
                        Usta Cüzdan - Usta Tezgahtar<br />
                        {(new Date().getFullYear())} - Softsis Yazılım Teknolojileri A.Ş.
                    </Col>
                </Row>
            </Container>
        </>
    )
};

export default Layout;