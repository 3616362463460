import { Container, Row, Col } from "react-bootstrap";

const Home = () => {
    return (
        <Container>
            <Row className='justify-content-md-center'>
                <Col md="auto">
                    <img src="https://ustacuzdan.com.tr/logo.jpg" />
                </Col>
            </Row>
        </Container>
    );
};

export default Home;