import { Breadcrumb, Form, Row, Col, Card, Button,Image } from 'react-bootstrap';
import { useState } from "react";
import InputMask from 'react-input-mask';

import * as formik from 'formik';
import * as yup from 'yup';

const Contact = () => {
    const { Formik } = formik;
    const [loading, setLoading] = useState(false);
    const [messageSend, setMessageSend] = useState(false);
    const schema = yup.object().shape({
        email: yup.string().email('Lütfen geçerli bir mail adresi giriniz.').required('E-Posta adresinizi girmeniz gerekmektedir.'),
        shortDesc: yup.string().min(10, "Lütfen en az '10' karakter giriş yapınız.").max(100, "Lütfen en fazla '100' karakter giriş yapınız.").required("Lütfen kısa bir özet giriniz."),
        message: yup.string().min(10, "Lütfen en az '10' karakter giriş yapınız.").max(500, "Lütfen en fazla '500' karakter giriş yapınız.").required("Lütfen mesajınızı yazınız."),
    });

    return (
        <>
            <div className='mt-4 mb-4'>
                <h3>Füller Usta Cüzdan - Tezgahtar Cüzdan</h3>
            </div>
            <Breadcrumb>
                <Breadcrumb.Item href="/help">Yardım Sayfası</Breadcrumb.Item>
                <Breadcrumb.Item active>Destek Talebi</Breadcrumb.Item>
            </Breadcrumb>
            <Card style={{ minHeight: '400px' }}>

                {!messageSend ?
                    <Card.Body>
                        <Card.Title className='mb-3 mt-2 fs-4'>Size Nasıl Yardımcı Olabiliriz?</Card.Title>
                        <Formik
                            validationSchema={schema}
                            onSubmit={(e) => {
                                setLoading(true);
                                setMessageSend(true);
                            }}
                            initialValues={{
                                email: '',
                                phone: '',
                                shortDesc: '',
                                message: ''
                            }}
                        >
                            {({ handleSubmit, handleChange, values, touched, errors, setFieldValue }) => (
                                <Form noValidate onSubmit={handleSubmit}>
                                    <Row className="mb-3">
                                        <Form.Group as={Col} controlId="email">
                                            <Form.Label>E-Posta Adresiniz: *</Form.Label>
                                            <Form.Control
                                                value={values.email}
                                                onChange={handleChange}
                                                isValid={touched.email && !errors.email}
                                                isInvalid={!!errors.email}
                                            />
                                            <Form.Text id="email" style={{ fontSize: 12 }}>
                                                Cevabımızı bu e-posta adresine göndereceğiz.
                                            </Form.Text>
                                            <Form.Control.Feedback type="invalid">
                                                {errors.email}
                                            </Form.Control.Feedback>
                                        </Form.Group>

                                        <Form.Group as={Col} controlId="phone">
                                            <Form.Label>Telefon Numaranız:</Form.Label>
                                            <InputMask
                                                mask="(999) 999 99 99"
                                                className="form-control"
                                                placeholder=""
                                                value={values.phone}
                                                isValid={touched.phone && !errors.phone}
                                                isInvalid={!!errors.phone}
                                                onChange={(e) => {
                                                    setFieldValue(
                                                        'phone',
                                                        e.target.value.replace(/\D/g, '')
                                                    );
                                                }}
                                            />
                                            <Form.Text id="phone" style={{ fontSize: 12 }}>
                                                Uygulamaya üye iseniz, uygulamada kullandığınız telefon numarasını yazınız.
                                            </Form.Text>
                                            <Form.Control.Feedback type="invalid">
                                                {errors.phone}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Row>

                                    <Form.Group className="mb-3" controlId="shortDesc">
                                        <Form.Label>Mesajınızın Kısa Özeti: *</Form.Label>
                                        <Form.Control
                                            placeholder=""
                                            value={values.shortDesc}
                                            onChange={handleChange}
                                            isValid={touched.shortDesc && !errors.shortDesc}
                                            isInvalid={!!errors.shortDesc}
                                            maxLength={100}
                                        />
                                        <Form.Text id="shortDesc" style={{ fontSize: 12 }}>
                                            Yaşadığınız sorunun veya öğrenmek istediğiniz konunun kısa özetiniz yazınız.
                                        </Form.Text>
                                        <Form.Control.Feedback type="invalid">
                                            {errors.shortDesc}
                                        </Form.Control.Feedback>
                                    </Form.Group>

                                    <Form.Group className="mb-3" controlId="message">
                                        <Form.Label>Mesaj İçeriği: *</Form.Label>
                                        <Form.Control
                                            as="textarea"
                                            rows={3}
                                            value={values.message}
                                            onChange={handleChange}
                                            isValid={touched.message && !errors.message}
                                            isInvalid={!!errors.message}
                                            maxLength={500}
                                        />
                                        <Form.Text id="message" style={{ fontSize: 12 }}>
                                            Mesajınızı olabildiğince detaylı olarak yazınız.
                                        </Form.Text>
                                        <Form.Control.Feedback type="invalid">
                                            {errors.message}
                                        </Form.Control.Feedback>
                                    </Form.Group>

                                    <Button variant="primary" type="submit">
                                        Gönder
                                    </Button>
                                </Form>
                            )}
                        </Formik>
                    </Card.Body> :
                    <Card.Body className='text-center pt-5 pb-5'>
                        <Image src="/ok-icon.png" roundedCircle width={120} />
                        <Card.Title className='mt-5 fs-3'>Mesajınız Alındı</Card.Title>
                        <Card.Text className='mt-4 fs-5'>Yazmış olduğunuz mesaj başarıyla alınmıştır en kısa sürede tarafınıza geri dönüş sağlanacaktır.</Card.Text>

                    </Card.Body>
                }
            </Card>
        </>
    );
};

export default Contact;